import Zoomist from 'zoomist';
import {ready} from "./utils.js";

ready(() => {
    if(document.getElementById("my-map")) {
        new Zoomist('#my-map', {
            height: '70%',
            maxRatio: 5,
            slider: true,
            zoomer: true,
            on: { ready() { this.zoomTo(2); } }
        });
    }
});