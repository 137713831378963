import $ from 'jquery'
//To Top Button
$(document).ready(function() {

    $(".arrow-next").on("click", function(e) {
        $('html, body').animate({
            scrollTop: $(this).parent().parent().next().offset().top  - 150
        }, 800);
    });

});