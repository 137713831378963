import Swiper, { Navigation, Pagination } from 'swiper';

const swiper1 = new Swiper('.mainswiper', {
    // Optional parameters
    modules: [Pagination],
    loop: true,
    autoplay: {
        delay: 200,
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
    },

});

const newsswiper = new Swiper('.swiper-news', {
    modules: [Navigation],
    slidesPerView: 1,
    spaceBetween: 20,
    loop: true,
    // Responsive breakpoints
    breakpoints: {
        // when window width is >= 480px
        600: {
            slidesPerView: 2,
            spaceBetween: 30
        },
        // when window width is >= 640px
        1000: {
            slidesPerView: 3,
            spaceBetween: 40
        }
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    }
});

const historyswiper = new Swiper('.swiper-history', {
    modules: [Navigation],
    slidesPerView: 1,
    spaceBetween: 20,
    loop: false,
    // Responsive breakpoints
    breakpoints: {
        // when window width is >= 480px
        600: {
            slidesPerView: 2,
            spaceBetween: 30
        },
        // when window width is >= 640px
        1000: {
            slidesPerView: 3,
            spaceBetween: 40
        },
        1200: {
            slidesPerView: 3,
            spaceBetween: 50
        }
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
    }
});

