import $ from "jquery";
import "what-input";
import libs from "./lib/dependencies.js";

window.$ = $;
window.jQuery = $;
window.libs = libs;

//import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import 'foundation-sites'
import "./lib/foundation-explicit-pieces.js";

//Pixmill Scripts
import { ready } from "./px/utils.js";

import "./px/hamburger.js";
import "./px/opaque.js";
import "./px/scrollnext.js";
import "./px/form.js";
import "./px/lazyload.js";
import "./px/swiper.js";
import "./px/zoomist.js";
//import { addFunctionalityToProgressPath } from "./px/scrollProgress.js";

// ready(function () {
//   addFunctionalityToProgressPath("progress-wrap");
// });


$(document).foundation();



      // change url on reveal open
  $(document).on("open.zf.reveal", function (e) {
    const idOfReveal = e.target.id;
    window.location.hash = idOfReveal;
  });

  const openRevealForHash = () => {
    const hash = window.location.hash;
    if (hash) {
      const revealElement = $(hash);
      if (revealElement.length > 0 && revealElement.hasClass("reveal")) {
        revealElement.foundation("open");
      }
    }
  };
  openRevealForHash();